<script lang="tsx">
import { defineComponentCoreIcon } from '@core/app/components/core/icon/CoreIcon.vue'

export default defineComponentCoreIcon(
    <svg viewBox="0 0 31 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M22 4.125H26.125L30.25 9.7075V17.875H27.445C27.115 20.2125 25.1075 22 22.6875 22C20.2675 22 18.26 20.2125 17.93 17.875H10.945C10.615 20.2125 8.6075 22 6.1875 22C3.7675 22 1.76 20.2125 1.43 17.875H0V1.375C0 0.61875 0.61875 0 1.375 0H20.625C21.3813 0 22 0.61875 22 1.375V4.125ZM22 6.875V11H27.5V10.615L24.7362 6.875H22Z"
            fill="currentColor"
        />
    </svg>
)

</script>

<style lang="scss" scoped>

</style>
